@import 'variables';

.accountContainer {
    font-size: 24px;
    padding: 30px 0;
}

.accountContainer--signup {
    font-size: 16px;
    text-align: center;
    padding: 20px 0 0;
}

.accountContainer--name {
    font-size: 24px;
    font-family: $bold;
}

.accountContainer--email {
    font-size: 14px;
}

.settings {
    &__button {
        background-color: $navColor;
        color: $activeWhite;
        border: 0;
        padding: 0;
        flex-grow: 0;
        font-family: $bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        width: 100%;
    }

    &__environment {
        flex-grow: 1;
        display: flex;
        align-items: end;
        padding: 20px 0;
    }
}

.loginContainer {
    padding: 30px 0;
}

.page-header {
    padding-top: 20px;
    font-family: $bold;
    font-size: 18px;
}

.page-text {
    padding-top: 20px;
}

.full-modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 60px;
    flex-direction: column;
}

.subscription-content {
    background-color: $sessionGray;
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px;
}

.subscription-header-content {
    margin-bottom: 40px;
}

.zls-sptwndw.siqembed.siqtrans.zsiq-mobhgt.zsiq-newtheme.siq_rht.zsiq_size2.siqanim {
    max-height: 90% !important;
}

.footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    font-size: 10px;
}

.footer a {
    text-decoration: none;
}
