@import '../global/fonts/fonts';
// colors
$primaryBlack: #1c1f20;
$primaryBlue: #144bff;
$secondaryRed: #ff236c;
$secondaryYellow: #ffb000;
$neutralBlack: #2a2f31;
$neutralDarkGray: #9a9da0;
$neutralLightGray: #dbdfe4;
$neutralWhite: #f0f0f0;
$white: #fff;
$sessionGray: #2c3235;
$secondaryGray: #727272;
$errorRed: #ff1414;

// TODO: Delete
$inactiveWhite: #9a9da0;
$activeWhite: #f0f0f0;
$navColor: #1c1f20;
$inactiveButton: #dbdfe4;
$activeButton: #144bff;

// font sizes
$xxsmall: 12px;
$xsmall: 14px;
$small: 16px;
$medium: 20px;
$large: 30px;

//component sizes
$navHeight: 60px;

// fonts
$regular: Lufga;
$bold: LufgaMedium;
