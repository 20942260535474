@import 'variables';

.home {
    &__header {
        padding: 0 0 20px;
        font-family: $bold;
    }

    &__welcome {
        font-size: 30px;
        padding-bottom: 0;
        font-family: $bold;
        line-height: 36px;
    }

    &__title {
        padding: 20px 0 10px;
    }
    &__map {
        height: 150px;
        & a,
        button {
            display: none;
        }
    }
}

.error-boundary {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    padding: 30px;
}

.error-boundary-text {
    color: $activeWhite;
}
