.navigator {
    background-color: $primaryBlack;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    padding-bottom: var(--bottom-inset);
    bottom: 0;

    &__container {
        height: $navHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #000;
    }

    &__button {
        background-color: transparent;
        border: none;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 0;
    }
}

.noSignal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 40px);
    height: 60px;
    bottom: calc(var(--bottom-inset) + 70px);
    padding: 10px 10px;
    box-sizing: border-box;
    margin: 0 20px;
    border-radius: 8px;
    background-color: $primaryBlack;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &__text {
        padding-left: 8px;
    }
}
