.radio-buttons {
    display: flex;
    flex-direction: column;
}

.payment-radio {
    display: flex;
    padding: 15px;
    background: $neutralBlack;
    margin: 5px 0;
    border-radius: 8px;
    align-items: flex-start;
    position: relative;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    &__notext {
        display: flex;
        padding: 15px;
        background: $neutralBlack;
        margin: 5px 0;
        border-radius: 8px;
        align-items: flex-start;
        position: relative;
        align-items: center;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
}

.inverse {
    .payment-radio {
        background: white;
        color: black;
        box-shadow: none;
        border: 1px solid $inactiveWhite;
        &__notext {
            background: white;
            box-shadow: none;
            color: black;
            border: 1px solid $inactiveWhite;
        }
    }

    .payment-radio.selected,
    .payment-radio__notext.selected {
        border: 2px solid $primaryBlue;
    }
}

.radio-title {
    font-family: $bold;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.radio-subtext {
    margin-top: 5px;
}

.payment-radio-input {
    display: none;
}

.payment-radio-button {
    width: 22px;
    height: 22px;
    border: 2px solid #727272;
    display: block;
    position: relative;
    border-radius: 50%;
    margin-right: 10px;
}

.payment-radio-button-checked {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
}

.toggle {
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-input {
    display: none;
}

.toggle-slider {
    width: 44px;
    height: 26px;
    background-color: $inactiveWhite;
    display: inline-block;
    border-radius: 26px;

    .toggle-ball {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        margin: 4px;
        transform: translateX(0px);

        transition: transform 0.5s;
    }
}

.toggle-slider-checked {
    width: 44px;
    height: 26px;
    background-color: $primaryBlue;
    display: inline-block;
    border-radius: 26px;
    display: flex;
    transition: transform 1s;

    .toggle-ball {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        margin: 4px;
        transform: translateX(18px);

        transition: transform 0.5s;
    }
}

.radio-item {
    display: flex;
    align-items: center;
    position: relative;
}

.payment-list-item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $neutralDarkGray;
    display: block;
    margin: 5px;
}

.payment-list-item-checked {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 14px;
    height: 14px;
    background-color: $neutralDarkGray;
    border-radius: 50%;
}

.radio-text {
    width: calc(100% - 32px);
    max-width: calc(100% - 32px);
}

.radio-error {
    font-size: $xxsmall;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
}

.radio-error-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}
