.discount-title {
    font-size: 18px;
    margin-top: 20px;
}

.discount-description {
    font-size: $xxsmall;
    margin-top: 5px;
}

.accordion {
    padding: 15px 20px;
    background-color: $sessionGray;
    border-radius: 8px;
    margin-top: 10px;
    overflow: hidden;
    width: 100%;
    border: 0;
    color: $activeWhite;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.accordion-action {
    display: flex;
    align-items: center;
}

.accordion-arrow {
    margin-left: 5px;
    transform: rotate(180deg);
}

.discount-content {
    display: flex;
    margin-top: 5px;
}

.discount-content a {
    font-size: 10px;
    color: $inactiveWhite;
    text-decoration: none;
}

.accordion .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.accordion-open {
    padding: 15px 20px;
    background-color: $sessionGray;
    border-radius: 8px;
    margin-top: 10px;
    overflow: hidden;
    width: 100%;
    border: 0;
    color: $activeWhite;
}

.accordion-open .accordion-content {
    max-height: 1000px;
    overflow: visible;
    transition: max-height 0.3s ease-in;
}

.accordion-open .accordion-arrow {
    transform: rotate(0);
}
