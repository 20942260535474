@import '../variables';
.map {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
    background-color: $sessionGray;
    border-radius: 6px;
    &__full {
        height: 100vh;
        border-radius: 0;
    }
}

.map__user-marker {
    width: 12px;
    height: 12px;
}

.map__home-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.map__marker-property {
    width: 26px;
    height: 26px;
    background-color: $primaryBlue;
    color: $activeWhite;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    border-radius: 50%;
}

.map__marker-property::before {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 20px solid #144bff;
}

.map__marker-user {
    width: 20px;
    height: 20px;
    background-color: #0086ff;
    border-radius: 50%;
}

.map__marker-property-selected {
    background-color: #1438af;
}

.map__marker-property-selected::before {
    border-top: 20px solid #1438af;
}

.map__marker-property-locked {
    width: 26px;
    height: 26px;
    background-color: $neutralDarkGray;
    color: $activeWhite;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    border-radius: 50%;
}

.map__marker-property-locked::before {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 20px solid $neutralDarkGray;
}

.map__marker-property-selected-locked {
    background-color: $secondaryGray;
}

.map__marker-property-selected-locked::before {
    border-top: 20px solid $secondaryGray;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
}

.mapboxgl-ctrl-bottom-left {
    display: none;
}

.map__back-button {
    position: absolute;
    z-index: 10000;
    top: 60px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.map__filter-button {
    position: absolute;
    z-index: 10000;
    top: 60px;
    right: 20px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.map__recenter-button {
    position: absolute;
    z-index: 10000;
    top: 120px;
    right: 20px;
    background: $white;
    border-radius: 6px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.map__redo-button {
    position: absolute;
    z-index: 2;
    top: 120px;
    right: 0;
    left: 0;
    width: 150px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    background: $white;
    border-radius: 6px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    color: $white;
    background-color: $primaryBlue;
    padding: 10px 10px;
    border: 0;
    cursor: pointer;
}

.modal__filter.modal__radio-label {
    color: $navColor;
    font-size: 18px;
}

//MAPBOX STYLES
/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
    box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
    font-size: 18px;
    line-height: 24px;
    font-family: $regular;
    position: relative;
    background-color: $white;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    transition:
        width 0.25s,
        min-width 0.25s;
    padding: 5px 0;
}

.container--full .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    display: flex;
    align-self: center;
    float: none;
    width: calc(100% - 160px);
    position: absolute;
    top: 60px;
    left: 80px;
    right: 80px;
    margin: 0;
}

.mapboxgl-ctrl-geocoder--input {
    font: inherit;
    width: 100%;
    border: 0;
    background-color: transparent;
    margin: 0;
    display: flex;
    justify-content: center;
    color: $navColor;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
}

.mapboxgl-ctrl-geocoder--input:focus {
    color: $navColor;
    outline: 0;
    box-shadow: none;
}

.mapboxgl-ctrl-geocoder--pin-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.mapboxgl-ctrl-top-right {
    width: 100%;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
    z-index: 2;
    display: none;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 50px;
    min-width: 50px;
    transition:
        width 0.25s,
        min-width 0.25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
    background-color: $white;
    border-radius: 4px;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 110%; /* fallback */
    top: calc(100% + 6px);
    z-index: 1000;
    overflow: hidden;
    font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
    top: auto;
    bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
    cursor: default;
    display: block;
    padding: 6px 12px;
    color: #404040;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
    color: #404040;
    background-color: #f3f3f3;
    text-decoration: none;
    cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
    font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
    display: inline-block;
    vertical-align: middle;
    speak: none;
    fill: $navColor;
    top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-left: 10px;
}

.mapboxgl-ctrl-geocoder--icon-search.mapboxgl-ctrl-geocoder--icon {
    display: none; /* Hide the default icon */
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl::before {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzNCAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjUyMzcgOS40MzE2OUMyNS45MTgyIDEyLjc4NzkgMjUuOTE4MiAxOC4yMjkzIDIyLjUyMzcgMjEuNTg1NUMxOS4xMjkxIDI0Ljk0MTcgMTMuNjI1NSAyNC45NDE3IDEwLjIzMSAyMS41ODU1QzYuODM2NDIgMTguMjI5MyA2LjgzNjQyIDEyLjc4NzkgMTAuMjMxIDkuNDMxNjlDMTMuNjI1NSA2LjA3NTUgMTkuMTI5MSA2LjA3NTUgMjIuNTIzNyA5LjQzMTY5IiBzdHJva2U9IiMyMTI3MkEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTI3LjA3NSAyNi4wODU1TDIyLjUxODMgMjEuNTgwMyIgc3Ryb2tlPSIjMjEyNzJBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    display: block;
    height: 30px;
    width: 30px;
    margin-left: 5px;
}

.mapboxgl-ctrl-geocoder--button {
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background: $white;
    line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
    width: 30px;
    height: 30px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
    fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-geolocate {
    width: 22px;
    height: 22px;
    margin-top: 6px;
    margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: 5px;
    margin-right: 0px;
    -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
    -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
    animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.mapboxgl-ctrl-geocoder--powered-by {
    display: none !important;
    float: left;
    padding: 6px 12px;
    padding-bottom: 9px;
    font-size: 13px;
}

/* Animation */
@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
