.button {
    color: $neutralWhite;
    border-radius: 25px;
    background-color: $primaryBlue;
    width: 100%;
    height: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &--secondary {
        background-color: rgb(0, 0, 0, 0);
        border: 1px solid $activeWhite;
        color: $activeWhite;
        border-radius: 25px;
        padding: 10px 20px;
    }

    &--pressed {
        background-color: #1438af;
        color: $neutralWhite;
    }

    &--disabled {
        background-color: $neutralDarkGray;
        color: $neutralLightGray;
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 25px;
    }

    &--small {
        width: 50%;
    }

    &--sso {
        width: fit-content;
        padding: 18px 45px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #2c3235;

        .page--light & {
            background-color: initial;
            border-radius: 10px;
            border: 1px solid #d8dadc;

            & > svg {
                margin-top: -3px;

                & > g > path {
                    fill: #000000;
                }
            }
        }

        & > svg {
            margin-top: -3px;
        }
    }
    &--transparent {
        background-color: rgb(0, 0, 0, 0);
        padding: 10px 20px;
        margin-top: 16px;
        color: $neutralWhite;
        width: 100%;
        height: 50px;
        border: none;

        &-black {
            margin-top: 0px;
            color: $neutralBlack;
        }
    }
}

.loginButton {
    font-size: 16px;
}

.close-button {
    position: absolute;
    top: 20;
    right: 20;
}

.settings-button-wrapper {
    display: flex;
    flex-direction: column;
}

.settings-buttons {
    background-color: $sessionGray;
    border-radius: 8px;
    font-size: $xsmall;
    color: $activeWhite;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px;
    border: 0;
    align-items: center;

    &--transparent {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }
}

.settings-button--title {
    font-family: $bold;
}

.tertiaryButton {
    width: 100%;
    color: $neutralWhite;
    border-radius: 25px;
    background-color: $navColor;
    width: 100%;
    height: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-badge-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.button-badge {
    float: right;
    margin-top: 20px;
}
