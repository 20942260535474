.account-header {
    background-color: $sessionGray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;
}

.account-header-title {
    color: $inactiveWhite;
    font-size: $xsmall;
}

.account-header-details {
    flex: 1;
}

.account-input,
.account-input:disabled {
    width: 100%;
    background-color: $sessionGray;
    border: 0;
    color: $activeWhite;
    padding: 5px 0;
    opacity: 1;
}

.result-message {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20;
    font-size: 18px;
}

.zsiq_floatmain {
    margin-bottom: var(--bottom-inset);
}
