.link {
    font-size: calc(var(--font-size) * 0.9);
    color: $neutralWhite;
    text-decoration: underline;
    text-decoration-color: $neutralWhite;
    &--blue {
        color: $primaryBlue;
    }

    &--initial-size {
        font-size: var(--font-size);
    }
}
