.line {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 30%;
        height: 1px;
        background-color: #d8dadc;
    }

    &::before {
        left: 0;
        transform: translateY(-50%);
    }

    &::after {
        right: 0;
        transform: translateY(-50%);
    }
}
