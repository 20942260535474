@import 'variables';

.session {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__header {
        display: flex;
    }

    &__spinner-container {
        display: flex;
        margin: 40px;
        position: relative;
    }

    &__spinner-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 25%;
        left: 25%;
        right: 25%;
        bottom: 25%;
    }

    &__spinner-style {
        width: 205px;
        height: 205px;
    }

    &__kwh {
        font-size: 42px;
        line-height: 42px;
    }

    &__kwh_title {
        font-size: 16px;
    }

    &__accordion {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        width: 100%;
        border-radius: 8px;
        background-color: $sessionGray;
        margin-bottom: 20px;
    }

    &__accordion-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px;
    }

    &__accordion-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 15px;
        transition: transform 0.3s;
        background-color: $sessionGray;
    }

    &__icon--home {
        width: 20px;
        height: 20px;
        transition: transform 0.3s;
        background-color: none;
        margin-right: 5px;
        padding: 0;
    }

    &__icon--session {
        width: 13px;
        height: 13px;
        margin-right: 5px;
    }

    &__accordion-icon-opened {
        transform: rotate(180deg);
    }

    &__dot {
        width: 4px;
        height: 4px;
        margin: 0 15px;
        color: $activeWhite;
    }

    &__accordion-content {
        font-size: 16px;
        color: $neutralDarkGray;
        max-height: 500px;
        transition: max-height 0.3s ease-in;
        overflow: hidden;
    }

    &__accordion-content-closed {
        max-height: 0;
        transition: max-height 0.3s ease-out;
    }

    &__accordion-content-container {
        padding: 0 20px 20px;
        margin-left: 35px;
        font-size: $xsmall;
    }

    &__accordion-tou-container {
        display: flex;
        flex-direction: row;
    }

    &__loadshare {
        margin-bottom: 5px;
    }

    &__toolbox {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        width: 100%;
        border-radius: 8px;
        background-color: $sessionGray;
        padding: 20px;
    }

    &__cost-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__price-title {
        font-size: $xsmall;
        color: $neutralDarkGray;
        margin-bottom: 5px;
    }

    &__price {
        font-size: 18px;
    }

    &__current-cost {
        font-size: 42px;
    }

    &__penalty-container {
        display: flex;
        background-color: $sessionGray;
        border: 0;
        justify-content: flex-start;
        padding: 0;
        text-align: start;
        align-items: flex-start;
        vertical-align: middle;
    }

    &__icon-small {
        width: 16px;
        height: 16px;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
    }

    &__accordion-tou-times {
        margin-right: 20px;
        text-align: right;
    }

    &__icon-medium {
        width: 24px;
        height: 24px;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
    }

    &--box {
        padding: 20px 0 0;
    }

    &__station_id {
        font-size: $xxsmall;
        padding: 5px 10px;
        background-color: #2c3235;
        display: inline-block;
        border-radius: 3px;
    }

    &__min-breakdown {
        font-size: 18px;
        display: flex;
        padding: 10px 0;
        align-items: center;
    }

    &__min-dot {
        margin: 0 5px;
    }

    &__min-kwh {
        font-size: 28px;
        text-align: end;
    }

    &__bar-style-finished {
        width: 100%;
        height: 10px;
        background-color: #ffb000;
    }

    &__bar-style-idle {
        width: 100%;
        height: 10px;
        background-color: #2c3235;
    }
}
