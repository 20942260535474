@import 'variables';

.scan {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1;

    &__manual-container {
        display: flex;
        flex-direction: column;
        // Only iOS needs to anchor to the bottom
        -webkit-justify-content: flex-end;
        height: 180px;
        position: fixed;
        left: 0;
        right: 0;
        padding: 0 20px;
        bottom: 30px;
        top: 180;
    }

    &__manual-content {
        display: flex;
        gap: 10px;
        margin-bottom: calc(var(--bottom-inset) + 60px);
        &:focus-within {
            margin-bottom: 30px;
        }
    }

    &__manual-text {
        margin-bottom: 5px;
    }

    &__input {
        width: 100%;
        flex-grow: 1;
        padding: 15px 10px;
    }

    &__button {
        background-color: $activeButton;
        border: 0;
        padding: 15px 30px;
        border-radius: 5px;
        color: $activeWhite;
        font-size: $small;
    }

    &__button:disabled {
        background-color: $inactiveButton;
        color: $neutralDarkGray;
    }

    &__camera {
        width: 100%;
        // height: 80%;
        flex: 1;
    }

    &__camera-focused {
        width: 100%;
        height: 0%;
    }
}
