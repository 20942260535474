@import 'variables';

.guestCheckout {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 100000;

    &--clear {
        top: auto;
    }

    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(18, 15, 15, 0.7);
        z-index: 0;
    }

    &__content {
        background-color: $navColor;
        position: relative;
        width: 100%;
        transition: transform 0.5s ease;
        z-index: 1;
        padding-bottom: var(--bottom-inset);
        margin-bottom: 10px;
    }

    &__content--inset {
        background-color: $navColor;
        height: var(--bottom-inset);
        z-index: 1;
    }

    &__content--inset--map {
        background-color: $white;
        height: var(--bottom-inset);
        z-index: 1;
    }

    &__grabber {
        position: absolute;
        top: 10px;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
    }

    &__content-error {
        background-color: $navColor;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        position: relative;
    }

    &__overlay {
        width: 100%;
        height: 100%;
        // position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: url('../assets/pricingBackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__filter-background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.73);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__gradient {
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(33, 39, 42, 0.75),
            rgba(33, 39, 42, 1)
        );
        height: 100px;
        width: 100%;
        position: relative;
    }

    &__full-content {
        background-color: $navColor;
        width: 100%;
        max-height: 50%;
        min-height: 50%;
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        z-index: 2;
        padding: 20px 20px 30px;
        // border: 2px solid;
    }

    &__close-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    &__filter-close-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__close {
        font-size: 50px;
        line-height: 40px;
        color: $neutralDarkGray;
        padding: 10px 20px;
    }

    &__filter-close {
        font-size: 35px;
        color: $neutralWhite;
    }

    &__title {
        font-size: 24px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        margin: 10px;
    }

    &__message {
        text-align: center;
    }

    &__button {
        width: 100%;
        // border: 2px solid green;
    }

    &__contact-container {
        // border: 2px solid;
    }

    &__contact {
        // margin-top: 30px;
        // position: absolute;
        // border: 2px solid pink;
    }

    &__contact-modal {
        margin-top: 20px;
        position: relative;
    }

    &__radio-container {
        background-color: #2a2f31;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 12px;
        margin-bottom: 8px;
        // display: flex;
        // align-items: center;
    }

    &__radio-container-selected {
        background-color: #2a2f31;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: 2px solid $primaryBlue;
        border-radius: 10px;
        padding: 12px;
        margin-bottom: 8px;
        // display: flex;
        // align-items: center;
    }

    &__flex-container {
        // display: flex;
        // flex-direction: column;
    }

    &__radio-description {
        display: block;
        text-align: left;
        margin-left: 32px;
        font-size: 16px;
    }

    &__radio {
        list-style-type: none;
        padding: 0;
        width: 100%;
        // margin-bottom: 50px;
    }

    &__radio-label {
        margin-left: 11px;
    }

    &__radio-li {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
}

.guestCheckbox-input {
    position: absolute;
    opacity: 0;
}

.guestCheckbox-custom {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $neutralDarkGray;
    border-radius: 50%;
}

.guestCheckbox-input:checked + .guestCheckbox-custom::after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-color: $primaryBlue;
    border-radius: 50%;
    margin: 2px;
}

.PhoneInputInput {
    font-family: $regular;
    color: $activeWhite;
    background-color: $neutralBlack;
    border: 0;
}

.guestCheckout__contact {
    width: 100%;
    background-color: $neutralBlack;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 8px;
}

.bubble-click {
    display: flex;
}

.bubble-wrapper {
    position: relative;
}

.bubble-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
}

.bubble {
    font-size: $xxsmall;
    background-color: #000;
    border-radius: 8px;
    padding: 10px;
    max-width: 230px;
    position: absolute;
    top: -65px;
    margin-left: 40px;
}

.bubble::after {
    content: '';
    position: absolute;
    top: calc(100% + 0px); /* Adjust the position as needed */
    left: calc(50% - 40px); /* Adjust as needed */
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
}

.bubble-center {
    width: 230px;
    left: -107px;
}
