@import '../variables';

.header {
    background-color: $navColor;
    color: $activeWhite;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid #000;
    border-color: #000;

    display: flex;
    flex-direction: column;
    padding-top: var(--top-inset);

    &__back {
        position: absolute;
        left: 0;
        padding: 0 20px;
    }
}

.header__content {
    padding: 20px;
    background-color: $navColor;
    width: 100%;
    text-align: center;
}

.inset__header {
    display: flex;
    height: var(--top-inset);
    background-color: $navColor;
    //to hide map
    z-index: 100000;

    &--invisible {
        opacity: 0;
        z-index: 1;
    }
}
