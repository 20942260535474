.loader {
    width: 20px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: white;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

.lg-loader {
    width: 100px;
    padding: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: white;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}

.slide-in-right {
    animation: slide-in-right 0.25s ease-out forwards;
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* HTML: <div class="loader"></div> */
.charging {
    width: 205px;
    height: 205px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, $secondaryYellow 94%, #0000) top/10px
            10px no-repeat,
        conic-gradient(#0000 5%, $secondaryYellow);
    -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 10px),
        #552121 0
    );
    animation: l13 1s infinite linear;
}
@keyframes l13 {
    100% {
        transform: rotate(1turn);
    }
}

/* HTML: <div class="loader"></div> */
.charging-bar {
    height: 8px;
    width: 100%;
    --c: no-repeat linear-gradient($secondaryYellow 60% #000);
    background: var(--c), var(--c), $navColor;
    background-size: 60% 100%;
    animation: l16 3s infinite;
}
@keyframes l16 {
    0% {
        background-position:
            -150% 0,
            -150% 0;
    }
    66% {
        background-position:
            250% 0,
            -150% 0;
    }
    100% {
        background-position:
            250% 0,
            250% 0;
    }
}
