.black {
    color: black;
    border-color: black;
}

.purchase-total-black {
    display: flex;
    justify-content: space-between;
    font-family: $bold;
    border-top: 1px solid black;
    margin-top: 10px;
    padding-top: 10px;
}
.title.black {
    color: black;
}

.bold {
    font-family: $bold;
}

.large {
    font-size: 30px;
    line-height: 36px;
}

.left {
    justify-content: flex-start;
}

.xsmall {
    font-size: 14px;
}

.xxsmall {
    font-size: 12px;
}

.gray-text {
    color: $inactiveWhite;
}

.white-text {
    color: $activeWhite;
}

.title {
    font-size: 24px;
    color: $activeWhite;
    font-family: $bold;
}
