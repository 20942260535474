@import '../variables';

.recent {
    &__empty {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__empty-text {
        color: $neutralDarkGray;
        font-size: $xsmall;
        line-height: $xsmall;
    }

    &__empty-text-logged-out {
        color: $neutralDarkGray;
        font-size: $xsmall;
        width: 50%;
    }

    &__signup {
        padding: 5px 20px;
        background-color: $sessionGray;
        flex: 1;
        border-radius: 20px;
        color: $activeWhite;
        font-size: $xsmall;
        border-width: 1;
        border-color: $activeWhite;
        display: inline-block;
    }

    &__stations {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    &__details {
        margin-left: 15px;
    }

    &__title {
        font-size: $xsmall;
        color: $activeWhite;
    }

    &__subtitle {
        font-size: $xxsmall;
        color: $neutralDarkGray;
        display: flex;
        align-items: center;
    }

    &__dot {
        width: 3px;
        margin: 0 5px;
    }

    &__start {
        color: $neutralWhite;
        border-radius: 20px;
        background-color: $primaryBlue;
        border: none;
        padding: 10px 20px;
        font-size: $xxsmall;
    }

    &__start--disabled {
        border-radius: 20px;
        background-color: $neutralDarkGray;
        color: $secondaryGray;
        border: none;
        padding: 5px 20px;
        margin-top: 10px;
        font-size: $xsmall;
    }

    &__button-container {
        margin-top: 10px;
    }
}
