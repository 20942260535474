.dropdown-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $sessionGray;
    color: 16px;
    border-radius: 8px;
    padding: 15px 20px;
}

.dropdown-list-container {
    display: flex;
    position: relative;
    width: 100%;
}

.dropdown-list-content {
    margin-top: 5px;
    justify-content: space-between;
    background-color: $sessionGray;
    color: 16px;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    transition: max-height 0.5s ease;
}

.dropdown-list-content-hidden {
    margin-top: 5px;
    background-color: $sessionGray;
    border-radius: 8px;
    color: 16px;
    position: absolute;
    max-height: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.dropdown-text {
    padding: 10px 20px;
    width: 100%;
    border-radius: 0;
}

.dropdown-selected {
    background-color: #34393d;
}

.rotate0 {
    transition: transform 0.5s ease;
    transform: rotate(0deg);
}

.rotate180 {
    transition: transform 0.5s ease;
    transform: rotate(180deg);
}
