.form {
    > * + * {
        margin-top: 20px;
    }

    &__error {
        color: $errorRed;
        font-size: var(--font-size-small);
    }
}

.input {
    background-color: #445053;
    width: 100%;
    padding: 17px;
    color: $neutralWhite;
    font-weight: 500;
    border: none;

    &.contact {
        background-color: #2c3235;
        border-radius: 5px;
        color: $activeWhite;

        &::placeholder {
            color: $activeWhite;
            opacity: 1;
        }
    }

    .page--light & {
        background-color: initial;
        border-radius: 5px;
        border: 1px solid $neutralDarkGray;
        color: initial;
    }

    &:focus {
        outline: solid 1px $primaryBlue;
    }

    &__icon {
        position: absolute;
        right: 17px;
    }

    &__checkbox {
        // Hide the actual checkbox
        > input[type='checkbox'] {
            display: none;
        }
        border: none !important;
        padding-left: 0px;
        display: flex;
        font-size: $xsmall;
        padding: 0;

        // Display a styled version of a checkbox
        // Can only do so with a base64 encoded version of an svg
        // &::before {
        //     display: inline-block;
        //     content: ' ';
        //     width: var(--font-size);
        //     height: var(--font-size);
        //     // padding-left: var(--font-size);;
        //     background-size: contain;
        //     background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjA2JyBoZWlnaHQ9JzIwNicgdmlld0JveD0nMCAwIDIwNiAyMDYnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PGNpcmNsZSBjeD0nMTAzJyBjeT0nMTAzJyByPSc5Nicgc3Ryb2tlPScjMzEzMTMxJyBzdHJva2Utd2lkdGg9JzE0Jy8+PC9zdmc+');
        // }

        // &--checked {
        //     &::before {
        //         background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNTAnIGhlaWdodD0nNTAnIHZpZXdCb3g9JzAgMCA1MCA1MCcgZmlsbD0nIzE0NEJGRicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48Y2lyY2xlIGN4PScyNScgY3k9JzI1JyByPScyMy43NScgc3Ryb2tlPScjMTQ0QkZGJyBzdHJva2Utd2lkdGg9JzIuNScvPjxwYXRoIGQ9J00xNiAyNkwyMS4yNjYyIDMxLjI2NjJDMjEuNjY3IDMxLjY2NyAyMi4zMjA0IDMxLjY1NDkgMjIuNzA2MSAzMS4yMzk2TDM1IDE4JyBzdHJva2U9J3doaXRlJyBzdHJva2Utd2lkdGg9JzIuNScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJy8+PC9zdmc+');
        //     }
        // }
    }
}

.form-checkbox-button {
    min-width: 20px;
    height: 20px;
    border: 2px solid $secondaryGray;
    display: block;
    position: relative;
    border-radius: 50%;
    margin-right: 5px;
}

.form-checkbox-button-checked {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
}

.dropdown__replacement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: $activeWhite;
    background-color: #2c3235;
    padding: 17px;
    border: none;
    border-radius: 5px;
    width: 100%;
}

.textarea {
    color: $activeWhite;
    background-color: #2c3235;
    padding: 17px;
    border: none;
    border-radius: 5px;
    width: 100%;
    min-height: 150px;
}
