@import '../variables';

.pricing {
    &__price-title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 5px;
    }

    &__price-details-title {
        color: $neutralDarkGray;
        font-size: 12px;
        padding: 20px 0 6px;
    }

    &__price-details-container {
        padding: 5px 0 0;
    }

    &__price-details {
        font-size: $small;
        line-height: $medium;
    }

    &__price-tou-container {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    &__tou-times {
        margin-right: 20px;
    }

    &__tou-detail {
        font-size: 14px;
    }

    &__tou-detail-highlight {
        font-size: 14px;
        color: $activeWhite;
    }

    &__show-full {
        max-height: 1000px;
        transition: transform 0.5s ease;
    }

    &__show-min {
        max-height: 0px;
        overflow: hidden;
        transition: transform 0.5s ease;
    }
}

.pricing-close-container {
    display: flex;
    justify-content: flex-end;
}

.guest-pricing-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
