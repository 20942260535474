.filterContainer {
    margin-bottom: 20px;
    display: flex;

    &__arrow {
        margin-top: 4.5px;
        margin-left: 8px;
    }
}

.filterButtonContainer {
    display: flex;
}

.filterText {
    font-size: 15px;
    color: #9a9da0;
}

.sessionsLine {
    width: 100%;
    height: 2px;
    border-color: #ffb000;
}

.sessionsNumber {
    font-size: 24px;
    flex: 1;

    &__text {
        font-size: 10px;
        color: #9a9da0;
        flex: 1;
    }
}

.kwhLine {
    width: 100%;
    height: 2px;
    border-color: #ff236c;
}

.kwhNumber {
    font-size: 24px;
    flex: 1;

    &__text {
        font-size: 10px;
        color: $neutralDarkGray;
        flex: 1;
    }
}

.chargingHistoryBarContainer {
    display: flex;
    align-items: flex-start;
    gap: 34px;

    &__metrics {
        display: flex;
        gap: 34px;
    }
}

.fragmentHr {
    width: 100%;
    height: 1px;
    background-color: $neutralDarkGray;
    border: 0;
}

.chargingHistoryList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    &__date-station {
        font-size: 14px;
        color: $neutralWhite;
        // font-family: $bold;
    }

    &__time-kwh {
        font-size: 12px;
        color: $neutralDarkGray;
    }

    &__amount {
        font-size: 18px;
        color: $neutralWhite;
        text-align: right;
    }
}

.chargingHistoryNoSessionsText {
    font-size: $xxsmall;
    text-align: center;
    padding: 20px;
    color: $neutralDarkGray;
}

.chargingHistoryLoggedOut {
    font-size: $xxsmall;
    text-align: center;
    padding: 20px;
    color: $neutralDarkGray;
}

.chargingHistoryLoggedOutContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $xxsmall;
    align-items: center;
    padding: 20px 0;
}

.chargingHistoryLoggedOutText {
    font-size: $xsmall;
    color: $neutralDarkGray;
    width: 50%;
}

.chargingHistory--link {
    width: 100%;
    text-align: right;
    font-size: $xsmall;
    padding: 10px 0;
    color: $neutralDarkGray;
}
