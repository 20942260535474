@import 'reset';
@import 'variables';

// Set accessibiilty font base size
:root {
    --font-scale: 1;
    --font-base-size: 16px;
    --font-base-size-small: 14px;
    --font-size: calc(var(--font-base-size) * var(--font-scale));
    --font-size-small: calc(var(--font-base-size-small) * var(--font-scale));

    --top-inset: 0px;
    --bottom-inset: 0px;
}

/* Prevent "bouncy" overscroll behavior*/
html,
body {
    overscroll-behavior-y: none;
    /* Set the font base size across the app */
    font-size: var(--font-size);
    background-color: $primaryBlack;
    color: $neutralWhite;
    font-family: 'Lufga';
}

input {
    /* Scale the height of the inputs alongside the labels */
    font-size: var(--font-size);
}
input:focus {
    outline: none;
}

h1,
h2,
h3,
h4 {
    font-family: 'LufgaMedium';
    font-weight: normal;
}
@import 'variables';
@import 'components/button';
@import 'components/radio';
@import 'components/form';
@import 'components/link';
@import 'components/navigator';
@import 'components/modal';
@import 'components/header';
@import 'components/layout';
@import 'components/line';
@import 'components/recent';
@import 'components/pricing';
@import 'components/map';
@import 'components/spinner';
@import 'components/account';
@import 'components/discounts';
@import 'components/text';
@import 'components/dropdown';

@import 'container';
@import 'settings';
@import 'scan';
@import 'session';
@import 'chargingHistory';
@import 'guestCheckout';
@import 'payment';
