
.App-header {
  border: solid 5px green;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}
