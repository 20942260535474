@import '../variables';

.modal {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 100000;

    &--clear {
        top: auto;
    }

    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(18, 15, 15, 0.7);
        z-index: 0;
    }

    &__content {
        background-color: $navColor;
        padding: 40px 30px 0;
        position: relative;
        width: 100%;
        transition: transform 0.5s ease;
        z-index: 1;
        padding-bottom: var(--bottom-inset);

        &--light {
            background-color: $neutralWhite;
            color: $neutralBlack;
        }
    }

    &__content--inset {
        background-color: $navColor;
        height: var(--bottom-inset);
        z-index: 1;
    }

    &__content--inset.white {
        background-color: $white;
    }

    &__content--inset--map {
        background-color: $white;
        height: var(--bottom-inset);
        z-index: 1;
    }

    &__grabber {
        position: absolute;
        top: 10px;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
    }

    &__content-error {
        background-color: $navColor;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        position: relative;
    }

    &__content-scan-signal {
        background-color: $white;
        color: #000;
        padding: 30px;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 1;
        position: relative;
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: var(--top-inset);
        bottom: 0;
        right: 0;
        left: 0;
        background: url('../../assets/pricingBackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__filter-background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.73);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__gradient {
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(28, 31, 32, 0.75),
            rgba(28, 31, 32, 1)
        );
        height: 100px;
        width: 100%;
        position: relative;
        // border: 1px solid green;
    }

    &__full-content {
        background-color: $navColor;
        width: 100%;
        height: calc(100% - 100px);
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        z-index: 2;
        padding: 20px 20px 30px;
    }

    &__close-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        top: 0;
        right: 0;
    }

    &__close-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
    }

    &__filter-close-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__close {
        font-size: 50px;
        line-height: 40px;
        color: $neutralDarkGray;
        padding: 20px 20px;
    }

    &__filter-close {
        font-size: 35px;
        color: $neutralWhite;
    }

    &__title {
        font-size: 24px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        margin: 10px;
    }

    &__message {
        text-align: center;
    }

    &__button {
        width: 100%;
    }

    &__radio {
        list-style-type: none;
        padding: 0;
    }

    &__radio-label {
        margin-left: 11px;
    }

    &__radio-li {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    &__extra-content-hidden {
        overflow: hidden;
        min-height: 0;
    }

    &__has-animation {
        transition: max-height 0.3s ease-in;
    }

    &__extra-content {
        overflow: scroll;
        min-height: 0;
    }

    &__subtitle-highlighted {
        background-color: $sessionGray;
        padding: 5px 10px;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__session {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--details {
            padding: 10px 80px;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            align-items: center;
        }

        &--details-title {
            font-size: 30px;
            padding: 10px 50px;
            text-align: center;
        }
    }

    &__details-bar {
        border: 0;
        width: 100%;
        font-size: $xsmall;

        &--kwh {
            border-top: 3px solid #144bff;
        }

        &--cost {
            border-top: 3px solid #ffb000;
        }

        &--time {
            border-top: 3px solid #ff236c;
        }
    }

    &__price-tou-container {
        display: flex;
    }

    &__tou-times {
        margin-right: 10px;
    }

    &__map {
        background-color: $white;
        padding: 40px 30px 10px;
        position: relative;
        width: 100%;
        transition: transform 0.5s ease;
        z-index: 1;
        border-radius: 6px;
        font-family: $regular;

        &--title {
            font-size: 18px;
            color: $navColor;
            font-family: $bold;
        }

        &--subtitle {
            font-size: $xsmall;
            color: $secondaryGray;
        }

        &--header {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            margin: 15px 0 5px;
        }

        &--private-header {
            color: $white;
            background: $secondaryGray;
            padding: 5px 10px;
            font-size: $xxsmall;
        }

        &--public-header {
            color: $white;
            background: $primaryBlue;
            padding: 5px 10px;
            font-size: $xxsmall;
        }

        &--subscription-header {
            color: $white;
            background: $secondaryYellow;
            font-family: $regular;
            padding: 5px 10px;
            margin-left: 10px;
            font-size: $xxsmall;
        }

        &--available {
            font-size: $xsmall;
            color: $primaryBlue;
        }

        &--pricing {
            color: $navColor;
            font-size: $xsmall;
        }

        &--disclaimer {
            padding: 20px 0;
            font-size: $xsmall;
            color: $secondaryGray;
        }
    }
}
.modal__header--row {
    margin-bottom: 10px;
}

.modal__unavailable--header {
    color: $navColor;
    background: $secondaryYellow;
    font-family: $regular;
    padding: 5px 10px;
    font-size: $xxsmall;
    margin-bottom: 10px;
    display: inline;
    vertical-align: middle;
}

.checkbox-input {
    position: absolute;
    opacity: 0;
}

.checkbox-custom {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $neutralDarkGray;
    border-radius: 50%;
}

.checkbox-input:checked + .checkbox-custom::after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-color: $neutralDarkGray;
    border-radius: 50%;
    margin: 2px;
}
