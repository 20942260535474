.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    &__full {
        height: 100vh;
    }
}

.container {
    background-color: $navColor;
    color: $activeWhite;
    padding: 0;
    margin: 0;
    font-family: $regular;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - $navHeight - var(--bottom-inset));
    direction: ltr;

    &--navless-has-header {
        height: calc(100vh - var(--bottom-inset) - var(--top-inset));
    }

    &--full {
        position: relative;
        height: 100%;
    }
}

.container--navless {
    background-color: $navColor;
    color: $activeWhite;
    padding: 0;
    margin: 0;
    font-family: $regular;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - var(--bottom-inset));
}

.container.noScroll {
    overflow-y: hidden;
}

.container.noNav {
    height: 100%;
    overflow-y: hidden;
}

.subcontainer {
    background-color: $sessionGray;
    border-radius: 10px;
    padding: 15px;
}

.page {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__has-scan {
        display: flex;
        flex: 1;
    }

    &--fullscreen {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--light {
        background-color: #ffffff;
        color: $primaryBlack;
    }

    &__close {
        font-size: 50px;
        color: $neutralDarkGray;
        position: absolute;
        text-align: end;
        top: var(--top-inset);
        right: 20px;
        line-height: 1;
        margin-top: 20px;
        &--black {
            color: $primaryBlack;
        }
    }
}

.content {
    // flex: 1;
    height: 100%;
    overflow-y: scroll;
    padding: 0 20px;
    position: relative;
}

.content-page {
    height: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content__close {
    display: flex;
    justify-content: flex-end;
}
